import { Routes } from '@angular/router';
import { AuthByCodeGuard } from '@app/guards/auth-by-code.guard';
import { AccountsState } from '@app/states/accounts/states/accounts.state';
import { AppealsState } from '@app/states/appeals/states/appeals.state';
import { BonusState } from '@app/states/bonus/states/bonus.state';
import { FeedbackState } from '@app/states/feedback/states/feedback.state';
import { RegistrationState } from '@app/states/registration/states/registration.state';
import { provideStates } from '@ngxs/store';
import { ROUTE_SERVICE_OUTLET, RouteOutletType, RouteService } from '@web-bankir/ui-kit/services/route';
import { VirtualCardState } from './states/virtual-card/states/virtual-card.state';
import { withStorageFeature } from '@ngxs/storage-plugin';

export const APP_ROUTES: Routes = [
  {
    path: 'modal',
    outlet: 'modal',
    providers: [RouteService, { provide: ROUTE_SERVICE_OUTLET, useValue: RouteOutletType.Modal }],
    loadChildren: () => import('@app/areas/modals/modals.routes').then((file) => file.MODALS_ROUTES),
  },
  {
    path: 'my-loan',
    redirectTo: 'cabinet/main/my-loan',
  },
  {
    path: 'registration',
    redirectTo: 'account/registration/step-one',
  },
  {
    path: '',
    canActivate: [AuthByCodeGuard],
    children: [
      {
        path: 'cabinet',
        providers: [
          RouteService,
          provideStates([AccountsState, AppealsState, BonusState, FeedbackState, VirtualCardState], 
          withStorageFeature([AccountsState, AppealsState, BonusState, FeedbackState, VirtualCardState]))
        ],
        loadChildren: () =>
          import('@app/areas/authenticated/authenticated.routes').then((file) => file.AUTHENTICATED_ROUTES),
      },
      {
        path: 'feedback',
        redirectTo: 'cabinet/feedback',
        pathMatch: 'full',
      },
      {
        path: '',
        providers: [
          RouteService, 
          provideStates([RegistrationState], 
          withStorageFeature([RegistrationState]))
        ],
        loadChildren: () =>
          import('@app/areas/unauthenticated/unauthenticated.routes').then((file) => file.UNAUTHENTICATED_ROUTES),
      },
    ],
  },
];
