import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { GlobalLoaderComponent } from '@app/components/global-loader/components/global-loader/global-loader.component';
import { EventsActions } from '@app/states/events/states/events.actions';
import { EventsState } from '@app/states/events/states/events.state';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { ToastComponent } from '@web-bankir/ui-kit/components/toast';
import { AnalyticService } from './services/analytic/analytic.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [RouterModule, ToastComponent, GlobalLoaderComponent]
})
export class AppComponent implements OnInit {
  public title = 'web-bankir-app';

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    @Inject('Window') private window: Window,
    private analyticService: AnalyticService
  ) {}

  @HostListener('window:load', ['$event'])
  onWindowLoad(event: Event) {
    this.store.dispatch(new EventsActions.RegisterYandexUserId());
    this.analyticService.init();
  }

  /**
   * Отправка метрики forward_to_lk{@link EventsState#forwardToLK}
   * при наличии query-параметра metric=forward_to_lk
   * и сброс query-параметра metric
   */
  public ngOnInit(): void {
    const url = new URL(this.window.location.href);

    if (url.searchParams.get('metric') !== 'forward_to_lk') {
      return;
    }

    url.searchParams.delete('metric');

    this.store.dispatch(new EventsActions.ForwardLK());
    this.store.dispatch(
      new Navigate(
        [],
        Array.from(url.searchParams.entries()).reduce((prev, curr) => {
          prev[curr[0]] = curr[1];
          return prev;
        }, {}),
        {
          relativeTo: this.route,
          replaceUrl: true,
          skipLocationChange: false,
        }
      )
    );
  }
}
