import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthActions } from '@app/states/auth/states/auth.actions';
import { takeUntil } from 'rxjs/operators';
import { HttpHandlerService } from '@app/services/http-handler/http-handler.service';
import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core'; 
import { environment } from '../../environments/environment';

export const AuthInterceptor: HttpInterceptorFn = (request: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
  const store = inject(Store);
  const httpHandler = inject(HttpHandlerService);

  if (request.headers.has('isNotCancelled')) {
    return next(request);
  }

  if (request.url.includes(environment.gate)) {
    return next(request).pipe(
      takeUntil(httpHandler.onCancelPendingRequests()),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
    );
  }

  const { token, userId } = store.selectSnapshot((state) => {
    if (new Date(state.Auth?.tokenExpired * 1000) > new Date()) {
      return { token: state.Auth.token, userId: state.Auth.userId };
    }

    return {};
  });

  if (!!token && !request.headers.has('Authorization')) {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  if (userId) {
    request = request.clone({
      setHeaders: {
        UserId: userId.toString(),
      },
    });
  }


  return next(request).pipe(
    takeUntil(httpHandler.onCancelPendingRequests()),
    catchError((error: HttpErrorResponse) => {

      if (error.status === 401) {
        store.dispatch(new AuthActions.Logout());
      }
      return throwError(() => error);
    }),
  );
}
